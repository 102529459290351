.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg {
  background-color: #243746;
  background-image: url('assets/imagen_fondo.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -10;
  overflow: auto;
}

.container-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.content-wrapper {
  min-height: 500px;
  background-color: #363636;
  box-shadow: 0 3px 15px rgba(0,0,0,.54);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 60px;
  color: #FFF;
}

.logoliqui {
  height: 45px;
  width: 250px;
}

.operacion-container {
  width: 100%;
}

.operacion-documentos-container {
  background-color: #FFF;
  border-radius: 4px;
  padding: 10px 20px;
}

.numero-operacion-text {
  text-align: center;
  color: #363636;
  font-weight: bold;
  font-size: 20px;
}

.titulo-documento {
  border-radius: 4px;
  padding: 5px 10px;
  color: #363636;
  font-weight: bold;
}

.boton-adjuntar {
  background-color: #775cf8;
  color: #FFF;
  width: 100%;
}

.boton-confirmar {
  background-color: #32b050;
  color: #FFF;
  width: 100%;
}

.boton-rechazar {
  background-color: #E04F3C;
  color: #FFF;
  width: 100%;
}

.boton-finalizar {
  margin-top: auto;
  background-color: #F9AC00;
  border-color: #F9AC00;
  color: #FFF;
  padding: 10px 30px;
  height: auto;
  font-weight: bold;
}

.finalizado-container {
  background-color: #FFF;
  border-radius: 8px;
}

.finalizado-container .ant-result-title {
  color: #4caf50;
}

.finalizado-container .ant-result-subtitle {
  color: #363636;
  font-size: 15px;
}

.error-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.error-container .ant-result-title {
  color: #FFF;
}

.error-container .ant-result-subtitle {
  color: #FFF;
  font-size: 15px;
}

.operacion-header-container {
  padding: 10px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #363636;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.operacion-header-number {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 3px solid #F9AC00;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #F9AC00;
  font-weight: bold;
  padding: 10px;
  background-color: white;
}

.operacion-collapse {
  width: 100%;
}

.ant-collapse {
  border: none !important;
}

.operacion-collapse .ant-collapse-header {
  font-weight: 500;
  border-radius: 8px !important;
}

.operacion-collapse .ant-collapse-content {
  border-radius: 8px !important;
  margin-top: 5px;
}

.modal-preview {
  width: 60vw !important;
}

.preview-frame {
  width: 100%;
  height: 50vh;
  margin-top: 40px;
}

.ant-modal-confirm-btns {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.btn-firmar-electronicamente {
  background-color: #775cf8;
}

.btn-firmar-electronicamente:hover {
  background-color: #775cf8 !important;
}

.btn-preview-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-preview-cerrar {
  background-color: #775cf8;
  color: #FFF;
}

.texto-explicativo {
  width: 50%;
  text-align: center;
  margin-bottom: 20px;
}

.area-documentos {
  background-color: #535353;
  width: 100%;
  padding: 15px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.doc-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ant-btn:hover {
  color: #FFF !important;
  opacity: 0.8 !important;
  border-color: #d9d9d9 !important;
}

.btn-finalizar-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.btn-finalizar {
  width: 30%;
  background-color: #F9AC00;
  border-color: #F9AC00;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  height: 55px;
}

.btn-finalizar:hover {
  border-color: #F9AC00 !important;
}

/* Estilos para vista móvil */
@media only screen and (max-width: 700px) {
  .content-wrapper {
    margin: 15px 0px;
    padding: 40px 30px;
  }

  .modal-preview {
    width: 95% !important;
  }

  .ant-modal-confirm-btns {
    flex-direction: column-reverse;
    gap: 8px;
  }

  .texto-explicativo {
    width: 100%;
  }

  .btn-finalizar {
    width: 100%;
  }
}